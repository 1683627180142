import React from "react";
import CardActions from "@material-ui/core/CardActions";
import {
  Show,
  SimpleShowLayout,
  TextField,
  ImageField,
  EditButton,
  RefreshButton,
  FunctionField,
} from "react-admin";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";

const PageShow = (props) => {
  const Title = ({ record }) => {
    return <span>Página {record ? `"${record.name}"` : ""}</span>;
  };

  const ShowActions = ({ basePath, data, resource }) => (
    <CardActions>
      <EditButton basePath={basePath} record={data} />
      <RefreshButton basePath={basePath} record={data} />
    </CardActions>
  );

  return (
    <Show title={<Title />} actions={<ShowActions />} {...props}>
      <SimpleShowLayout rowClick={"edit"}>
        <TextField source="id" label="ID" />
        <TextField source="name" label="Nome" />,
        <TextField source="description" label="Descrição" />
        <ImageField source="main_image_url" label="Imagem 1" />
        <Typography>Salas</Typography>
        <FunctionField
          source="rooms"
          render={(record) => (
            <Grid container spacing={12}>
              {record?.rooms?.map((room) => (
                <Grid item spacing={8}>
                  <Chip
                    key={room.id.toString()}
                    label={room.name}
                    avatar={<Avatar alt={room.name} src={room.thumbnail_url} />}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        />
      </SimpleShowLayout>
    </Show>
  );
};

export default PageShow;
